:root {
  --theme-black: #010101;
  --theme-white: #FFFFFF;
}
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
html {
  font-size: 10px;
}
body {
  background: var(--theme-black);
  color: var(--theme-white);
}

.bg {
  &--white {
    background: var(--theme-white);
  }
}

.tungsten {
  font-family: var(--theme-font-03);
}
.text--uppercase {
  text-transform: uppercase;
}
