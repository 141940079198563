@font-face {
  font-family: 'Fixture Expanded';
  src: url('../fonts/Fixture-ExpandedBlack.woff2') format('woff2'),
  url('../fonts/Fixture-ExpandedBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
  url('../fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Medium.woff2') format('woff2'),
  url('../fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Black.woff2') format('woff2'),
  url('../fonts/Gilroy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Circular Std';
  src: url('../fonts/CircularStd-Black.woff2') format('woff2'),
  url('../fonts/CircularStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../fonts/CircularStd-Medium.woff2') format('woff2'),
  url('../fonts/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fixture Condensed';
  src: url('../fonts/Fixture-CondensedExtraBold.woff2') format('woff2'),
  url('../fonts/Fixture-CondensedExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../fonts/CircularStd-Bold.woff2') format('woff2'),
  url('../fonts/CircularStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Tungsten';
  src: url('../fonts/Tungsten-Bold.woff2') format('woff2'),
  url('../fonts/Tungsten-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fixture';
  src: url('../fonts/Fixture-SemiBold.woff2') format('woff2'),
  url('../fonts/Fixture-SemiBold.woff') format('woff');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

.scale{
  font-family: 'Fixture', sans-serif;
  font-variation-settings: 'wght' 400; /* Initial weight */
  transition: font-variation-settings 0.3s ease;
}
.your-text {
  font-family: 'Roboto Flex', sans-serif;
  font-variation-settings: 'wght' 100; /* Initial weight */
  transition: font-variation-settings 0.3s ease;
}
