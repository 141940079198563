:root {
  --theme-font-01: 'Fixture';
  --theme-font-02: 'Circular Std';
  --theme-font-03: 'Tungsten';
  --theme-font-04: 'Gilroy';
  --theme-font-05: "Roboto Flex", sans-serif;
}
body {
  font-family: var(--theme-font-04);
}


