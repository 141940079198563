$tablet: 1024px;
.big-text {
  font-size: 9rem;
  text-transform: capitalize;
  font-weight: 500;
  @media (min-width: $tablet){
    margin-top: vw(-10);
    margin-right: vw(40);
    font-size:vw(90);
  }
}
.section {
  height: 100vh;
  display: flex;
  align-items: center;
  min-width: 100vw;
}
.wrapper {
  width:fit-content;
  display:flex;
  flex-wrap:nowrap;
  height: 100%;
}
.inner-container {
  position: absolute;
  left:0;
  width: 100vw;
  top:0;
  height: 100%;
  pointer-events: none;
}
.info{
  //@media (max-width: $tablet){
  //  display: none;
  //}
  position: absolute;
  bottom:3rem;
  right: 2rem;
  //width: 30rem;
  //height: 30rem;
  //border: solid 1px var(--theme-white);
  //border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--theme-font-04);
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  @media (min-width: $tablet){
    font-size: vw(19);
    //width: vw(300);
    //height: vw(300);
  }
  @media (max-width: $tablet){
    left:0;
    right:0;
    marign:0 auto;
  }
}
.text {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  span {
    letter-spacing: 0.18rem;
  }
  &-01 {
    padding-left: 20rem;
    @media (min-width: $tablet){
      padding-left: vw(200);
    }
  }
  &-02 {
    padding-left: 100vw;
    font-size:2.3rem;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    margin-right: 60vw;
    span:first-of-type {
      @media (min-width: $tablet){
        margin-left: vw(40);
      }
    }
    span:nth-of-type(2){
      @media (min-width: $tablet){
        margin-right: vw(40);
      }
    }
    @media (min-width: $tablet){
      font-size: vw(23);
    }
  }
  &-03 {
    margin-left: -5vw;
    font-size:2.3rem;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    z-index: 3;
    @media (min-width: $tablet){
      font-size: vw(23);
    }
  }
  &-04 {
    font-size:2.3rem;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    z-index: 3;
    @media (min-width: $tablet){
      font-size: vw(23);
    }
    span {
      text-transform: uppercase;
    }
    .key {
      @media (min-width: $tablet){
        margin-left: vw(30);
        margin-right: vw(30);
      }
      svg {
        @media (min-width: $tablet){
          width: vw(162);
          height: vw(162);
        }
      }
    }
  }
  &-05 {
    @media (min-width: $tablet){
      margin-left: vw(40);
    }
  }
  &-05, &-06, &-07 {
    font-size:2.3rem;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    z-index: 3;
    @media (min-width: $tablet){
      font-size: vw(23);
    }
    span {
      text-transform: uppercase;
      opacity:0.5;
    }
  }
  .split-text {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size:2.3rem;
    @media (min-width: $tablet){
      font-size: vw(23);
    }
    .logo {
      margin: 0 4.5rem;
      @media (min-width: $tablet){
        margin: 0 vw(45)
      }
      svg {
        width: 47.4rem;
        @media (min-width: $tablet){
          width: vw(474);
        }
      }
    }

    span {
      margin: 0 0.5rem;
      @media (min-width: $tablet){
        margin: 0 vw(5);
      }
      opacity: 0;
    }
  }
}

.expanded {
  font-size:29.8rem;
  font-family: var(--theme-font-05);
  font-weight: 900;
  margin: 0 0.5rem;
  @media (min-width: $tablet){
    font-size: vw(298);
    margin: 0 vw(5);
  }
}
.new-font {
  transition: font-family 0.3s ease;
  font-family: var(--theme-font-05);
  font-weight: 100;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}
.marker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 10px;
  @media (min-width: $tablet){
    gap: vw(5);
  }
  .last {
    position: absolute;
    //top: 50%;
    //transform:translateY(-50%);
    left: 3rem;
    margin-left: 10px;
    @media (min-width: $tablet){
      left: vw(30);
    }
  }
  .marker {
    font-size: 4.5rem;
    @extend .new-font;
    //font-weight: 900;
    opacity: 0;
    @media (min-width: $tablet){
      font-size:vw(450);
    }
  }
  .new {
    position: absolute;
    right: 30%;
    top: 0;
    svg {
      &:first-of-type {
        position: absolute;
        top: 1rem;
        left: -1rem;
      }
      transform-origin: center;
      @media (min-width: $tablet){
        width: vw(219);
        height: vw(220);
        //margin-left: vw(-20);
      }
        animation: spin 36s linear infinite;
        transform-origin: center;

    }
    p {
      position: absolute;
      color: var(--theme-black);
      top:50%;
      transform: translateY(-50%);
      text-transform: uppercase;
      width: 100%;
      text-align: center;
      margin:0;
      font-weight: 900;
      font-family: var(--theme-font-04);
      letter-spacing: 0.18rem;
    }
  }
}
#shadow {
  filter: opacity(0.5) blur(1px);
  position: absolute;

}
.number-spinner {
  max-width: 10.3rem;
  @media (min-width: $tablet){
    max-width: vw(103);
  }
  box-shadow:
    inset 5px 5px 10px rgba(0, 0, 0, 0.5);  /* Top left shadow */
  background-color: var(--theme-white);
  border-radius: 0.7rem;
  overflow: hidden;
  position: absolute;
  left: 10rem;
  bottom: 3rem;
  @media (min-width: $tablet){
    border-radius: vw(7);
    left: vw(100);
    bottom: vw(50);
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
    inset 10px 0 10px -10px rgba(0, 0, 0, 0.5); /* Left shadow */
    pointer-events: none;
  }
  ul {
    list-style: none;
    display: flex;
    gap:1rem;
    margin:0;
    padding: 0 1rem;
    align-items: center;
    height: 3.6rem;
    @media (min-width: $tablet){
      height: vw(36);
      padding: 0 vw(10);
    }
    li {
      color:var(--theme-black);
      font-weight: 500;
      font-size:2.3rem;
      @media (min-width: $tablet){
        font-size: vw(23);
      }
    }
  }
}
.image-wrapper {
  position: absolute;
  right:-30vw;
  z-index: -1;
  overflow: hidden;
  border-radius: 4rem;
  .image-bg {
    position: relative;
    height: 93vh;
    object-fit: cover;
    border-radius: 4rem;
    .frame{
      position: absolute;
      left: -13%;
      top: -103px;
      //@media (min-width: $tablet){
      //  top: vw(-103);
      //}
      mix-blend-mode: multiply;
    }
    img:nth-of-type(2){
      object-fit: cover;
      height: 100%;
    }
    &:after {
      content:"";
      background: var(--theme-black);
      opacity: 0.35;
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
    }
  }
}
.image-scroller {
  min-width: 10.48rem;
  width: 100%;
  overflow: hidden;
  border-radius: 4rem;
  box-shadow: -11px 1px 16px 20px #1212128f;
  //&-1 {
  //  padding-left: 60vw;
  //}
  //&-2 {
  //  padding-left: 60vw;
  //}
  @media (min-width: $tablet){
    min-width: vw(1048);
  }
  .image-bg {
    position: relative;
    height: 93vh;
    object-fit: cover;
    border-radius: 4rem;
    overflow: hidden;

    .frame{
      position: absolute;
      left: -13%;
      top: -103px;
      mix-blend-mode: multiply;
      @media (min-width: $tablet){
        top: vw(-103);
      }
    }
    img:nth-of-type(2){
      object-fit: cover;
      height: 100%;
      //width: 100%;
      //width: vw(1048);
    }
    &:after {
      content:"";
      background: var(--theme-black);
      opacity: 0.35;
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
    }
  }
  &-1 {

  }
  &-2 {
    z-index: 2;
    margin-left: 60vw;
  }
}
.text-03 {
  //height: 22.5rem;
  //align-items: flex-start;
  span {
    text-transform: uppercase;
    opacity: 0.5;
  }
}
.big-title {
  font-family: var(--theme-font-04);
  font-weight: 900;
  font-size: 14.1rem;
  text-transform: uppercase;
  line-height: 0.8;
  margin-right: 3rem;
  margin-top: 19rem;
  opacity: 1!important;
  @media (min-width: $tablet){
    font-size: vw(141);
    margin-top: vw(190);
    margin-right: vw(30)
  }
  p {
    margin: 0;
  }
}
.image-home {

  .flat-iron-text {
    font-size: 37.7rem;
    @media (min-width: $tablet){
      font-size: vw(377);
      font-weight: 900;
      font-family: var(--theme-font-03);
    }
  }
  position: relative;
  //height: 94vh;
  margin-left: 4rem;
  //overflow: hidden;
  //border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    z-index: 9;
    @media (min-width: $tablet){
      min-width: 450px;
     width: vw(450);
      height: auto;
      margin-right: vw(-75);
      margin-left: vw(-75);
      margin-top: vw(280);
    }
  }
  @media (min-width: $tablet){
    //width: vw(636);
    margin-left: vw(40);
  }
  .frame {
    position: absolute;
    mix-blend-mode: multiply;
    left: -111px;
    top:-50px;
    @media (min-width: $tablet){
      top: vw(-107);
      left: vw(-111);
    }
  }
  img {
    height: 100%;
    &:nth-of-type(2){
      height: 100%;
    }
  }

}

.text-04 {
  position: relative;
  //margin-left: 300px;
  //@media (min-width: $tablet){
  //  margin-left: vw(300);
  //}
  .date-spinner {
    max-width: 26.3rem;
    box-shadow:
      inset 5px 5px 10px rgba(0, 0, 0, 0.5);  /* Top left shadow */
    background-color: var(--theme-white);
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    right: -153px;
    bottom: 60.6px;
    height: 6.7rem;
    @media (min-width: $tablet){
      max-width: vw(117);
      right: vw(-153);
      right: vw(0);
      bottom: vw(20);
      bottom:33%;
      //bottom: vw(63.6);
      height: vw(40);
    }
    display: flex;
    align-items: center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
      inset 10px 0 10px -10px rgba(0, 0, 0, 0.5); /* Left shadow */
      pointer-events: none;
    }
    ul {
      list-style: none;
      display: flex;
      gap:1rem;
      margin:0;
      padding: 0 1rem;
      align-items: center;
      height: 3.6rem;
      @media (min-width: $tablet){
        height: vw(36);
        padding: 0 vw(10);
        gap: vw(10);
      }
      li {
        color:var(--theme-black);
        font-weight: 500;
        font-size:3.2rem;
        @media (min-width: $tablet){
          font-size: vw(16);
        }
      }
    }
  }
}

.big-number-wrapper {
  position: relative;
  //padding-left: 160vw;
  padding-left: 12vw;
  min-width: vw(1283);
  .big-number {

    font-size:69.7rem;
    font-family: var(--theme-font-04);
    font-weight: 900;
    @media (min-width: $tablet){
      font-size: vw(697);
    }
  }
  .arrow {
    position: absolute;
    top:10%;
    right:-2%;
    .inner-arrow {
      z-index: 9;
      width: 20rem;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: var(--theme-white);
      position: relative;
      top:10%;
      right:-2%;
      padding:1rem;
      @media (min-width: $tablet){
        width: vw(200);
        height: vw(200);
        padding: vw(10);
      }
    }
   .shadow-circle {
     width: 21rem;
     height: 21rem;
     background: var(--theme-black);
     //opacity: 0.3;
     position: absolute;
     z-index: 0;
     bottom: -18px;
     border-radius: 100%;
     position: absolute;
     top: 35px;
     right: 20px;
     opacity: 0.3;
     @media (min-width: $tablet){
       width: vw(210);
       height: vw(210);
       bottom: vw(-18);
       top: vw(35);
       right: vw(20);
       top: 13%;
       right: -1%;
     }
   }
  }
  #spinner {
    animation: spin 45s linear infinite;
    transform-origin: center;
  }
  .inner-circle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.travel-badges {
  display: flex;
  align-items: center;
  margin-left: 4rem;
  @media (min-width: $tablet){
    margin-left: vw(40);
  }
  img {
    position: relative;
    &:nth-of-type(2){
      margin-left: -54px;
      top: 30px;
      @media (min-width: $tablet){
        margin-left: vw(-54);
        top: vw(30);
      }
    }
    &:nth-of-type(3){
      margin-left: -110px;
      top: -55px;
      @media (min-width: $tablet){
        margin-left: vw(-130);
        top: vw(-41);
      }
    }
    &:nth-of-type(4){
      margin-left: -64px;
      @media (min-width: $tablet){
        margin-left: vw(-64);
      }
    }
  }
}
.text-06 {
  margin-left: 0.5rem;
  @media (min-width: $tablet){
    margin-left: vw(5);
  }
}
.text-07 {
  margin-left:100vw;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100vh;
  position: relative;
  span:first-of-type {
    margin-left: vw(-55);
  }
  span:last-of-type {
    margin-right: 20px;
  }
}

.push-inner {
  padding-top: vw(200);
}

.logo {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .co-logo {
    margin-left: 4rem;
    @media (min-width: $tablet){
      margin-left: vw(40);
    }
    img {
      width: 11.2rem;
      width:vw(112);
    }
  }
  .line {
    width: 100%;
    max-width: 73.5rem;
    //min-width: 45rem;
    height: 2px;
    @media (min-width: $tablet){
      min-width: 200px;
      height: vw(2);
    }

    margin: 0 0.5rem;
    background: var(--theme-white);
    transition: linear all 0.4s;
  }
}
.fixed-one {
  max-width: 300px;
}
.black-logo {
  margin-left:100vw;
  min-width: 100vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-section {
  height: auto; /* Adjust as needed for enough scrollable space */
  .section {
    justify-content: center;
    align-items: flex-start;
    height: auto;
  }
}

.vertical-section.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: auto; /* Adjust if needed */
  //background-color: white; /* Optional */
  z-index: 1000; /* Ensure it's above other content */
}
.inner {
  svg {
    transition: ease-in-out all 0.4s;
  }
}
//body:has(.sticky){
//  .inner {
//    svg {
//      opacity: 0;
//    }
//  }
//}

.logo-black {
  transition: transform 0.3s ease;
  transform: translateY(100px);
}

.logo-black.shrink {
  transform: scale(0.5) !important; /* Adjust as needed */
}
//.vertical-section {
//  min-height: 100vh;
//  .section  {
//    height: 20vh;
//    display: flex;
//    align-items: cent9i
//    justify-content: center;
//    width: 100vw;
//  }
//}

.content {
  overflow: hidden;
  font-family: var(--theme-font-04);
  //min-height: 100vh;
  width: 100%;
  display: block;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
  .inner {
    text-align: center;
    svg {
      //margin-top: 400px;
      margin-bottom:90px;
      @media (min-width: $tablet) {
        margin-top: vw(400);
        margin-bottom:vw(90);
      }
    }
  }
  p {
    color: var(--theme-black);
    font-size: 1.9rem;
    text-align: center;
    max-width: 56ch;
    font-weight: 500;
    margin:0 auto;
    margin-bottom: 40px;
    line-height: 40px;
    letter-spacing: 0.14rem;
    @media (min-width: $tablet){
      font-size:vw(19);
      line-height: vw(40);
      margin-bottom: vw(40);
    }
  }
}
.footer {
  margin-top: 80px;
  border-top: solid 1px rgba(black, 0.4);
  padding: 54px 0;
  width: 100%;
  @media (min-width: $tablet){
    margin-top:vw(80);
    padding-bottom: vw(54);
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    @media (min-width: $tablet){
      gap: vw(40);
    }
    span, a {
      font-size: 1.9rem;
      letter-spacing: 0.16rem;
      color: var(--theme-black);
      @media (min-width: $tablet){
        font-size: vw(19);
      }
    }
    a {
      border-bottom: solid 1px rgba(black, 0.4);
      text-decoration: none;
    }
  }
}
.logo-black {
  transition: ease-in-out all 0.4s;
}
:root {
  font-size: 13px;
  --color-text: #fff;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #fff;
  --img-width: 190px;
  --img-ratio: 1.1;
  --img-offset-x: 20px;
  --img-offset-y: 20px;
  --img-border-radius: 7px;
  --color-bg-content: rgba(255,255,255,0.05);
  --color-bg-demos: rgba(255,255,255,0.05);
}

.content__img {
  width: var(--img-width);
  width: 200px;
  aspect-ratio: var(--img-ratio);
  border-radius: var(--img-border-radius);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  will-change: transform, filter;
  pointer-events: none;
}

.content__img-inner {
  background-position: 50% 50%;
  width: calc(100% + var(--img-offset-x));
  height: calc(100% + var(--img-offset-y));
  background-size: cover;
  position: absolute;
  top: calc( -1 * var(--img-offset-y) / 2 );
  left: calc( -1 * var(--img-offset-x) / 2 );
}


.desktop-only {
  @media (max-width: 1023px){
    display: none!important;
  }
}

.mobile-only {
  @media (min-width: $tablet){
    display: none!important;
  }
}

@media (max-width: 1023px){

  .mobile-content {
    min-height: 100vh;
  }
  .flat-iron, .team-image {
    margin:2rem 0;
    width: 100%;
  }
  img:not(.logo-black img) {
    max-width: 100%;
    //border-radius: 3rem;
  }
  .logo-black {
    max-width: 100%;
  }
  p {
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.16rem;
  }
  .container {
    padding: 0 3rem;
  }
  header {
    padding:2rem 0;
    .logo {
      img {
        max-width: 20rem;
        height: auto;
      }
    }
  }
  #matter-container-mobile {
    //height: 50vh;
    position: absolute;
    width: 100%;
    top:0;
  }
  .mobile-logo-collective {
    svg {
      max-width: 50vw;
    }
  }
  .intro {
    //min-height: 50vh
    position: relative;
    //padding-bottom:400px;
  }

  .moving {
    .key {

      svg {
        max-width: 100px;
        height: auto;
      }
    }
  }
  .flat-iron-wrapper {
    position: relative;
    width: 100%;
  }
  .date-spinner {
    background: white;
    position: absolute;
    bottom:3rem;
    right:0;
    max-width: 100px;
    padding:1rem;
    border-radius: 5px;
    box-shadow:
      inset 5px 5px 10px rgba(0, 0, 0, 0.5);  /* Top left shadow */
    background-color: var(--theme-white);
    border-radius: 0.7rem;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
      inset 10px 0 10px -10px rgba(0, 0, 0, 0.5); /* Left shadow */
      pointer-events: none;
    }
    ul {
      padding:0;
      margin:0;
      list-style: none;
      display: flex;
      flex-wrap: nowrap;
      gap: 1rem;
      transform: translateX(-24px);
    }
    li {
      color: var(--theme-black);
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  .new-clients {
    .big-numbers-wrapper {
      position: relative;
      width: max-content;
      .arrow {
        position: absolute;
        width: cl(90, 200);
        top: -13%;
        right: -14%;
      }
    }
    .big-numbers {
      font-size: cl(200, 400);
      line-height: 1;
      margin:0;
    }

    .travel-badges {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .last-section {
    margin-top: 6rem;
    padding: 4rem 0;
    .logo-black {
      text-align: center;
      img {
        max-width: 20rem;
        height: auto;
      }
    }
    .container {
      margin-top: 4rem;
    }
    p {
      text-align: center;
      font-size: 1.4rem;
      color: var(--theme-black);
      line-height: 40px;
      margin-bottom: 40px;
    }
    .footer {
      margin-top: 4rem;
      border-top: solid 1px rgba(black, 0.4);
      padding-top: 1rem;
      padding-bottom:1rem;
      span, a {
        font-size: 1.1rem;
      }
      .container {
        display: flex;
      }
    }
  }

  .travel-badges img:nth-of-type(3) {
    margin-top:0;
  }
  .travel-badges {
    margin-left:0;
  }
  .travel-badges img {
    margin:0!important;
    top:0!important;
  }

}

@media (max-width: 640px){

  .footer {
    .container {
      flex-direction: column;
    }
  }
}

#matter-container {
  height: 100vh;
  @media (min-width: $tablet){
    max-width:600px;
    flex: 0 1 71.436%;
  }
}


//.pinner {
//  position: fixed;
//  top: 0;
//  transform: scale(0.5);
//}


/*
Mobile
 */
.mobile-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  top:0;
  left:0;
  section {
    min-height: 100vh;
    width: 100%;

  }
}
#matter-container-mobile {
   overflow: hidden;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}
.first {
  .intro-text {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    p {
      font-size: 6rem;
      //opacity: 0;
      font-weight: 500;
      text-transform: capitalize;

    }
  }

}
.text-block-01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    margin:0;
    font-size: 32px;
  }


   p {
     opacity: 0;
     transform: translateY(100px);
   }

  svg {
    margin-top: 4.2rem;
  }
}

.text-block-02 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  p:first-of-type {
    font-size: 3.2rem;
  }
}

.panel {
  //position: absolute;
  width: 100%;
  height: 100%;
  //top: 0;
  //left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.second {
  img {
    margin-top: 20px;
    width: 100%;
  }
}

.third {
  position: relative;
  p:first-of-type {
    margin-top: 40px;
    font-size: 32px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  .big-text-wrapper {
    position: relative;
    padding-left: 3rem;
    * {
      font-family: var(--theme-font-05);
    }
  }
  .big-text-mobile-marker {

  }
  //video {
  //  transform: scale(0.1); /* Initial state */
  //}
  .number-spinner-mobile {
    margin-bottom: 40px;
    border-radius: 6px;
    box-shadow:
      inset 5px 5px 10px rgba(0, 0, 0, 0.5);  /* Top left shadow */
    background-color: var(--theme-white);
    overflow: hidden;
    max-width: 85px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
      inset 10px 0 10px -10px rgba(0, 0, 0, 0.5); /* Left shadow */
      pointer-events: none;
    }
    ul {
      list-style: none;
      display: flex;
      gap:1rem;
      margin:0;
      padding: 0 1rem;
      align-items: center;
      height: 3.6rem;
      li {
        color:var(--theme-black);
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}
.panel.fourth {

  p {
    margin:0;
  }
  .pin-fourth {
    font-size: 32px;
  }
  #expandedGif {
    width: 100%;
  }
  .images-mobile {
    margin-top: -37px;
    position: relative;
    img {
      max-width: calc(100% - 30px);
      margin:0 auto;
    }
    img:nth-of-type(2),
    img:nth-of-type(3) {
      position: absolute;
      width: 100%;
      height: 100%;
      top:10px;
      left:0;
      right:0;
      z-index: 2;
    }
    img:nth-of-type(3){
      top:40px;
    }
    img:nth-of-type(1){
      top: -22px;
      position: relative;
    }
  }

  .expanded-mobile {
    font-family: var(--theme-font-05);
    font-weight: 300;
    font-size: 50px;
    letter-spacing: 0.03em;
    margin-top:47px;
    position: relative;
    z-index: 1;
  }

  .big-creative {
    font-weight: 900;
    z-index: 99;
    position: relative;
    margin-bottom: -100px;
    p {
      font-family: var(--theme-font-04);
      margin:0;
      font-size:70px;
      letter-spacing: -0.02em;
      &:last-of-type {
        margin-top:-45px;
      }
    }
  }
}

.panel.fifth {
  img {
    width: 100%;
  }
  .key {
    margin-bottom: 28px;
  }
  p {
    margin: 0;
    font-size: 32px;
  }
  .key {
    svg {
      max-width: 74px;
      height: auto;
    }
    #bg {
      animation: spin 36s linear infinite;
      transform-origin: center;
    }
  }
}

.panel.sixth {
  display: block;
  position: relative;
  .date-spinner-mobile {
    top:95px;
    margin-bottom: 40px;
    border-radius: 6px;
    box-shadow:
      inset 5px 5px 10px rgba(0, 0, 0, 0.5);  /* Top left shadow */
    background-color: var(--theme-white);
    max-width: 125px;
    width: 100%;
    left: 26px;
    position: absolute;
    overflow-x: hidden;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5), /* Top shadow */
      inset 10px 0 10px -10px rgba(0, 0, 0, 0.5); /* Left shadow */
      pointer-events: none;
    }
    ul {
      list-style: none;
      display: flex;
      gap:1rem;
      margin:0;
      padding: 0 1rem;
      align-items: center;
      height: 46px;
      li {
        color:var(--theme-black);
        font-weight: 500;
        font-size: 18px;
      }
    }

  }
  p {
    font-weight: 900;
    font-family: var(--theme-font-03);
    font-size:150px;
    z-index: 9;
    position: relative;
  }
  .image-home-mobile {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    img {
      align-self: flex-end;
      max-height: 80vh;
      margin-bottom:-16px;
    }
  }
}

.panel.seventh {
  .big-number-mobile {
    font-family: var(--theme-font-04);
    font-weight: 900;
    font-size: 200px;
    margin-bottom:-80px;
  }
  p {
    font-size: 32px;
  }
  .number-wrap {
    position: relative;
  }
  .arrow {
    position: absolute;
    top:0;
    right:0;
  }

  .arrow {

    .inner-arrow {
      z-index: 9;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: var(--theme-white);
      position: absolute;
      top:10%;
      right:-2%;
      padding:1rem;
    }
    .shadow-circle {
      width: 100px;
      height: 100px;
      background: var(--theme-black);
      position: absolute;
      z-index: 0;
      bottom: -21px;
      border-radius: 100%;
      position: absolute;
      top: 18px;
      right: 10px;
      opacity: 0.3;
    }
  }
  #sspinner {
    animation: spin 45s linear infinite;
    transform-origin: center;

  }
  .inner-circle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    svg {
      width: 20px;
      height: auto;
    }
  }
}

.panel.eighth {
  p {
    margin:0;
    font-size: 32px;
  }

  .tags {
    display: flex;
    flex-wrap: nowrap;
    transform: scale(0.7);
    transform-origin: left;
    img {
      object-fit: contain;
      &:first-of-type {
        margin-right: -100px;
      }
      &:nth-of-type(2){
        margin-top: 50px;
        margin-right: -90px;
      }
      &:nth-of-type(3){
        margin-top: -30px;
      }
      &:nth-of-type(4){
        margin-top: 40px;
        margin-left: -40px;
      }
    }
  }
}

.panel.ninth {

  background: var(--theme-black);
  p {
    font-size: 32px;
    margin-bottom: 70px;
  }

  .co-logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
    width:100%;
    max-width: 90%;
    margin: 0 auto;
    .co-logo {
      max-width: 80px;
    }
    .five {
      max-width: 30px;
      width: 100%;
    }

    .line {
      width: 100%;
      height: 2px;
      background: white;
    }
    img {
      border-radius: 0;
    }
  }
}

.footer-block {

  .co-logo-wrap {
    position: fixed;
    top:0;
    filter:invert(1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap:1rem;
    width:100%;
    .co-logo {
      max-width: 80px;
    }
    .five {
      max-width: 40px;
      width: 100%;
    }

    .line {
      width: 10%;
      height: 2px;
      background: white;
    }
    img {
      border-radius: 0;
    }
  }

  background: var(--theme-white);
  color: var(--theme-black);
  min-height: 100vh;
  display: block;
  p {
    padding:0 12px;
    text-align: center;
    font-size: 18px;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom:2rem;
    text-transform: none;
  }
}

.co-logo-wrap {
  position: relative; /* Default position */
  width: 100%; /* Start with full width */
  transition: all 0.3s ease; /* Smooth transition for any changes */
}

@media (max-width: $tablet){
  .sticky {
    //position: fixed; /* Make the element sticky */
    top: 0; /* Stick it to the top */
    z-index: 1000; /* Ensure it stays on top */
    opacity: 0;
  }
}

.secondary-logo {
  opacity: 0;
  top: 41px!important;

  z-index: 2;
  &.show {
    opacity: 1;
  }
}
.footer-block:has(.show) {
  &:after{
    content: "";
    position: fixed;
    height: 300px;
    background: linear-gradient(-180deg, white 63%, #ffffff00);
    width: 100%;
    z-index: 1;
    top: -55px;
    left: 0;
  }
}

.scroll-downs {
  //position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  margin-left: 2rem;
  width: vw(34);
  height: vw(55);
  @media (max-width: $tablet){
    width: 34px;
    height: 55px;
    margin:0;
    margin-left: 2rem;
  }
}
.mousey {
  width: vw(3);
  padding: vw(10) vw(15);
  height: vw(35);
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  @media (max-width: $tablet){
    width: 3px;
    padding: 10px 15px;
    height: 35px;
  }
}
.scroller {
  width: vw(3);
  height: vw(10);
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;

  @media (max-width: $tablet){
    width: 3px;
    height: 10px;
  }
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}
.vertical-section {
  margin-top: -50vh;
}
body {
  .big-text {
    opacity: 0;
  }


}

body.loaded {
  .big-text {
    opacity: 1;
  }
}
